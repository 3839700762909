import styled from 'styled-components';
import { mediaQueries } from '../theme';

export const Wrapper = styled.article`
  display: flex;
  margin: 0 -30px;

  ${mediaQueries.COMPACT} {
    flex-wrap: wrap;
  }
`;

export const Column = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  min-height: calc(100vh - 144px);
  padding: 30px 30px 60px 30px;
  width: 50%;

  a {
    position: relative;
    text-decoration: none;

    &:after {
      border-bottom: 2px solid transparent;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
    }

    &:hover:after {
      border-bottom-color: inherit;
    }
  }

  ${mediaQueries.COMPACT} {
    min-height: auto;
    width: 100%;
  }
`;
