import React, { useContext, useEffect } from 'react';
import BlockContentToReact from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import { GlobalState } from '../../store';

import { Wrapper, Column } from '../../styles/about';
import NewsletterForm from '../../components/NewsletterForm';

const AboutPage = ({ data, pageContext }) => {
  const {
    allSanityAbout: {
      edges: [
        {
          node: { leftColumn, rightColumn },
        },
      ],
    },
  } = data;

  const globalStateDispatch = useContext(GlobalState.Dispatch);

  useEffect(() => {
    globalStateDispatch({
      type: 'setTheme',
      theme: {
        color: '#fff',
        background: '#000',
      },
    });
  }, [globalStateDispatch]);

  return (
    <Wrapper>
      <Column>
        <BlockContentToReact blocks={leftColumn} />
      </Column>
      <Column>
        <BlockContentToReact blocks={rightColumn} />
        <NewsletterForm />
      </Column>
    </Wrapper>
  );
};

export default AboutPage;

export const AboutPageQuery = graphql`
  query AboutPage {
    allSanityAbout(limit: 1) {
      edges {
        node {
          id
          leftColumn: _rawLeftColumn
          rightColumn: _rawRightColumn
        }
      }
    }
  }
`;
