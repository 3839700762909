import React, { useState } from 'react';
import jsonp from 'jsonp';

import { Button, Form, Flex, Input, Label } from './style';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('IDLE');

  const handleSubmit = evt => {
    evt.preventDefault();
    setStatus('LOADING');

    const url = `${evt.target.action}&EMAIL=${email}`;
    jsonp(url, { param: 'c' }, (err, data) => {
      if (data.msg.includes('already subscribed')) {
        setStatus('DUPLICATE');
      } else if (err) {
        setStatus('ERROR');
      } else if (data.result !== 'success') {
        setStatus('ERROR');
      } else {
        setStatus('SUCCESS');
      }
    });
  };

  return (
    <Form
      action="https://sidenotemagazine.us2.list-manage.com/subscribe/post-json?u=9dc7dda6213007a83a9c8d15b&amp;id=8e49bd66c6"
      method="POST"
      onSubmit={handleSubmit}
    >
      <Label>Sign up below to receive our newsletter:</Label>
      <Flex>
        <Input
          onChange={evt => setEmail(evt.target.value)}
          value={email}
          type="email"
          required
        />
        <Button disabled={status === 'LOADING'}>Submit</Button>
      </Flex>
      {status === 'DUPLICATE' ? (
        <p>You are already subscribed.</p>
      ) : status === 'ERROR' ? (
        <p>Something went wrong.</p>
      ) : status === 'SUCCESS' ? (
        <p>Thanks! You have been added to our list.</p>
      ) : null}
    </Form>
  );
};

export default NewsletterForm;
