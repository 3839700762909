import styled from 'styled-components';

export const Form = styled.form`
  margin-top: 60px;

  p {
    margin-bottom: 0;
    margin-top: 20px;
  }
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
`;

export const Label = styled.label`
  display: block;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  background: none;
  border: solid 2px;
  border-color: inherit;
  border-radius: 0;
  height: 70px;
  font-family: inherit;
  font-size: inherit;
  flex-grow: 1;
  flex-shrink: 1;
  line-height: inherit;
  margin-right: 20px;
  outline: none;
  padding: 0 15px;
  width: calc(100% - 90px);
`;

export const Button = styled.button`
  background: none;
  border: solid 2px;
  border-color: inherit;
  border-radius: 50%;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: inherit;
  font-size: 16px;
  font-weight: inherit;
  outline: none;
  height: 70px;
  width: 70px;

  &:disabled {
    animation: rotate;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;
